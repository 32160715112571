import React from "react";
import { Datagrid, List, SelectInput, TextField, TextInput } from "react-admin";
import { storageProvider } from '../../providers/storage-provider';

const ClassList = (props) => {

    const superAdmin = storageProvider.getCurrentUser()?.superAdmin

    const filters = [
        <TextInput label='Nome' source={"q"} alwaysOn />,
        <SelectInput label="Período letivo" source="academic_period" emptyText={"Todos"} emptyValue={"all"} choices={[{ name: "Vigente", id: "current" }]} alwaysOn />,
    ];

    return (
        <List exporter={false} bulkActionButtons={false}
            filters={filters}
            filterDefaultValues={{ "academic_period": "current" }}
            sort={{ field: 'academic_period.start_at', order: 'DESC' }}
            title={'Turmas'}
            {...props}
        >
            <Datagrid rowClick={'edit'}>
                <TextField source="name" label={'Nome'} />
                <TextField source="academic_period.name" label={'Período Letivo'} sortBy="academic_period.start_at" sortByOrder="DESC" />
                {superAdmin && <TextField source="institution.name" label={'Instituição'} />}
            </Datagrid>
        </List>
    )
}

export default ClassList;