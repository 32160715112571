import { Menu } from "@material-ui/icons";
import React from "react";
import { AutocompleteInput, Button, Create, FormTab, ReferenceInput, TabbedForm, required, useNotify, useRedirect } from "react-admin";

const SchoolActivityCreate = (props) => {

    const redirect = useRedirect()
    const notify = useNotify()

    const onSuccess = ({ data }) => {
        notify(`Atividade prática criada com sucesso`);
        redirect(`/school-activity-dashboard/${data.id}`);
    };

    return <Create onSuccess={onSuccess}
        actions={<Button style={{ marginBottom: '16px' }} variant='contained' label='Retornar' startIcon={<Menu />} onClick={() => redirect('/school-activities')} />}
        {...props}>
        <TabbedForm redirect="list">
            <FormTab label="Dados Gerais">
                <ReferenceInput source="model_activity" reference="school-model-activities" perPage={99} filterToQuery={(text) => ({ "school-model-activity.name": text })} sort={{ field: 'name', order: 'ASC' }} filter={{ published: true }} label="Atividade matriz" validate={[required()]} fullWidth>
                    <AutocompleteInput source="name" label="Atividade matriz" options={{
                        suggestionsContainerProps: {
                            modifiers: {
                                computeStyle: {
                                    enabled: true,
                                    gpuAcceleration: false,
                                },
                                applyStyle: {
                                    enabled: true,
                                },
                            },
                        }
                    }} fullWidth />
                </ReferenceInput>
                <ReferenceInput source="class" label="Turma" reference="classes" filter={{ academic_period: "current" }} validate={[required()]} fullWidth>
                    <AutocompleteInput source="name" label="Turma" options={{
                        suggestionsContainerProps: {
                            modifiers: {
                                computeStyle: {
                                    enabled: true,
                                    gpuAcceleration: false,
                                },
                                applyStyle: {
                                    enabled: true,
                                },
                            },
                        }
                    }} fullWidth />
                </ReferenceInput>
                <ReferenceInput source="teacher" label="Professor responsável" reference="users" filter={{ profiles: ["'Professor'", "'Administrador'", "'Coordenador'"] }} filterToQuery={(text) => ({ name: text })} validate={[required()]} fullWidth>
                    <AutocompleteInput source="name" label="Professor responsável" options={{
                        suggestionsContainerProps: {
                            modifiers: {
                                computeStyle: {
                                    enabled: true,
                                    gpuAcceleration: false,
                                },
                                applyStyle: {
                                    enabled: true,
                                },
                            },
                        }
                    }} fullWidth />
                </ReferenceInput>
            </FormTab>
        </TabbedForm>
    </Create>
}


export default SchoolActivityCreate 