import React from "react";
import { BooleanField, Datagrid, DateField, FilterButton, List, ReferenceInput, SearchInput, SelectInput, TextField } from "react-admin";
import cookiesProvider from "../../providers/cookies-provider";
import { storageProvider } from "../../providers/storage-provider";

const ProcessList = (props) => {

    const superAdmin = storageProvider.getCurrentUser()?.superAdmin;
    const permissions = storageProvider.getCurrentUser()?.permissions;
    const userId = cookiesProvider.getUser();
    const filters = [
        <SearchInput placeholder="Protocolo" source="alias" alwaysOn />,
        <ReferenceInput source="institution_branch" reference="institution-branches" sort={{ field: superAdmin ? 'institution.name' : 'name', order: 'ASC' }} perPage={99} label={'Unidade'} alwaysOn>
            <SelectInput source="name" optionText={record => superAdmin ? `${record.institution.name} - ${record.name}` : record.name} />
        </ReferenceInput>,
        <SelectInput label="Status" source="active" choices={[{ name: "Abertos", id: "true" }, { name: "Arquivados", id: "false" }]} alwaysOn />,
        <SearchInput placeholder="Cliente" source="cliente" />
    ];
    const studentPermission = !permissions.find(item => item.resourceName === 'processes').write
    const filter = studentPermission ? { [`user.id`]: userId } : undefined

    return (
        <List
            exporter={false}
            bulkActionButtons={false}
            filter={filter}
            filters={filters}
            filterDefaultValues={{ active: true }}
            title={`${studentPermission ? 'Meus ' : ''}Atendimentos`}
            sort={{ field: 'created_at', order: 'DESC' }}
            actions={<FilterButton />}
            {...props}
        >
            <Datagrid rowClick={(id, basePath, record) => studentPermission ? `/process-dashboard/${id}` : `/process-dashboard/${id}`}>
                <TextField source="alias" label={'Protocolo'} />
                {superAdmin && <TextField source="institution.name" label={'Instituição'} />}
                <TextField source="institution_branch.name" label={'Unidade'} />
                <TextField source="client.name" label={'Cliente'} />
                <BooleanField source="active" label={'Aberto'} />
                <DateField source="created_at" label={"Criado em"} />
            </Datagrid>
        </List>
    )
}


export default ProcessList;