import React from "react";
import { AutocompleteInput, BooleanInput, Create, FormDataConsumer, FormTab, ReferenceInput, required, TabbedForm, TextInput, useNotify, useRedirect, useRefresh } from "react-admin";
import MaskedInput from "../../components/masked-input";

const PartyCreate = (props) => {

    const redirect = useRedirect()
    const refresh = useRefresh();
    const notify = useNotify();

    const { redirectTo, process } = props.history.location.state ? props.history.location.state : { redirectTo: "list", process: undefined }

    const onSuccess = () => {
        notify('Parte criada com sucesso')
        redirect(redirectTo)
        refresh()
    }

    const transform = (data) => {
        return {
            ...data,
            process: process ? process : undefined,
        }
    }

    return (
        <Create {...props} onSuccess={onSuccess} transform={transform}>
            <TabbedForm submitOnEnter={false}>
                <FormTab label={'Dados Gerais'}>
                    <TextInput source="name" label={'Nome'} fullWidth validate={[required()]} autoComplete="off" />
                    <TextInput source="email" label={'Email'} type='email' fullWidth autoComplete="off" />
                    <FormDataConsumer>
                        {
                            ({ formData, ...rest }) => {
                                const mask = formData.cpf?.length < 14 ? '999.999.999-99' : '00.000.000/0000-00'
                                return <MaskedInput source="cpf" label={'CPF/CNPJ'} onlyNumbers={true} mask={mask} fullWidth autoComplete="off" />
                            }
                        }
                    </FormDataConsumer>
                    <TextInput source="rg" label={'RG'} fullWidth />
                    <ReferenceInput source="type" label="Tipo" reference="party-types" perPage={99} filterToQuery={(text) => ({ "party-type.name": text })} sort={{ field: 'party-type.name', order: 'ASC' }} validate={[required()]} fullWidth autoComplete="off">
                        <AutocompleteInput source='name' optionText={'name'} options={{
                            suggestionsContainerProps: {
                                modifiers: {
                                    computeStyle: {
                                        enabled: true,
                                        gpuAcceleration: false,
                                    },
                                    applyStyle: {
                                        enabled: true,
                                    },
                                },
                            }
                        }}
                        />
                    </ReferenceInput>
                    <MaskedInput source="phone" onlyNumbers={true} mask={'(99) 99999-9999'} label={'Telefone'} fullWidth autoComplete="off" />
                    <BooleanInput source="isRepresented" label={'Representado pelo NPJ'} autoComplete="off" />
                </FormTab>
                <FormTab label={'Endereço'} path={'address'}>
                    <MaskedInput source="address.cep" onlyNumbers={true} mask={'99.999-999'} label={'CEP'} fullWidth autoComplete="off" />
                    <TextInput source="address.logradouro" label={'Logradouro'} fullWidth autoComplete="off" />
                    <TextInput source="number" label={'Nº'} fullWidth autoComplete="off" />
                    <TextInput source="complement" label={'Complemento'} fullWidth autoComplete="off" />
                    <TextInput source="address.bairro" label={'Bairro'} fullWidth autoComplete="off" />
                    <TextInput source="address.localidade" label={'Cidade'} fullWidth autoComplete="off" />
                    <TextInput source="address.uf" label={'UF'} fullWidth autoComplete="off" />
                </FormTab>
            </TabbedForm>
        </Create>
    )
}




export default PartyCreate;